import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { Global } from '@emotion/react';
import { RKTheme } from '@robinpowered/design-system';
import { cssReset } from './cssReset';
import { DateProvider } from '@robinpowered/dashboard-apps-common';
import {
  AmplitudeProvider,
  ApolloContextProvider,
  AuthProvider,
  LocalizationProvider,
  TableProvider,
  ToastProvider,
  useAuthContext,
  LocationsProvider,
  WhosInProvider,
  useApolloContext,
} from './contexts';
import { GlobalErrorBoundary } from 'components/GlobalErrorBoundary';
import RedirectToDashboardHomepage from 'components/RedirectToDashboardHomepage';
import { PageMessage, RobinLoader } from 'components';
import { useEffect } from 'react';
import { App } from 'App';
import { config } from './config';
import Compose from 'contexts/Compose';
import { i18n } from './i18n';

const { robinEnv } = config;

export const DocumentHead = (): JSX.Element => {
  const { t } = useTranslation('common');
  return (
    <Helmet>
      <title>{t('default_page_title')}</title>
    </Helmet>
  );
};

const AuthController = (): JSX.Element => {
  const { t } = useTranslation('error');
  const { orgSlug } = useParams<'orgSlug'>();
  const {
    loading: isAuthLoading,
    setOrgSlug,
    isLoggedIn,
    error,
  } = useAuthContext();

  const loading = isAuthLoading;

  useEffect(() => setOrgSlug(orgSlug), [orgSlug, setOrgSlug]);

  useEffect(() => {
    const redirectToDashboard =
      !loading && robinEnv !== 'development' && !isLoggedIn;

    if (redirectToDashboard) {
      window.location.href = config.dashboardUrl;
    }
  }, [loading, isLoggedIn]);

  switch (true) {
    case loading:
      return <RobinLoader />;

    case !isLoggedIn && robinEnv === 'development':
      return <PageMessage title={t('unauthenticated')} />;

    case !!error:
      return <PageMessage title={t('try_again')} />;

    case robinEnv === 'production' && !isLoggedIn:
      return <RedirectToDashboardHomepage />;

    default:
      return <App />;
  }
};

const RootRouteTree = (): JSX.Element => {
  const { t } = useTranslation('error');
  return (
    <Routes>
      <Route path="/" element={<PageMessage title={t('missing_slug')} />} />
      <Route path="*" element={<RedirectToDashboardHomepage />} />
      <Route path=":orgSlug/people" element={<AuthController />} />
    </Routes>
  );
};

const AppContainerDate = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const { tenantId } = useApolloContext();
  const { currentUser } = useAuthContext();
  return (
    <DateProvider tenantId={tenantId} userId={currentUser?.id}>
      <Compose components={[LocationsProvider, TableProvider, WhosInProvider]}>
        {children}
      </Compose>
    </DateProvider>
  );
};

export const AppContainer = (): JSX.Element => {
  return (
    <GlobalErrorBoundary>
      <Global styles={cssReset} />
      <Compose
        components={[
          RKTheme,
          BrowserRouter,
          ApolloContextProvider,
          AuthProvider,
          LocalizationProvider,
          ToastProvider,
          HelmetProvider,
          AmplitudeProvider,
        ]}
      >
        <I18nextProvider i18n={i18n}>
          <AppContainerDate>
            <DocumentHead />
            <RootRouteTree />
          </AppContainerDate>
        </I18nextProvider>
      </Compose>
    </GlobalErrorBoundary>
  );
};
